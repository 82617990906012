/* Copyright (C) Andreas Goelzer - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Andreas Goelzer <agolzer@agolzer.com>, 2019
 */

import React, { Component } from "react";
import MvsdaChurchImg from "../../resources/img/mvsda/mvsdachurch.jpg";

export default class MvsdaFrame extends Component {
  backgroundStyle = {
    display: "flex",
    backgroundImage: `url(${MvsdaChurchImg})`,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center center",
    backgroundAttachment: "fixed",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    width: "100vw",
    height: "100vh",
    margin: "0",
    padding: "0",
    border: "0",
    //overflow: "hidden",
  };

  backgroundBoxStyle = {
    backgroundColor: "rgba(255, 255, 255, 0.7)",
    backdropFilter: "blur(5px)",
    width: "calc(100%-10px)",
    display: "flex",

    flexDirection: "column",
    justifyContent: "center",
    alignItems: "flex-start",
    //width: "min(50vw, 50vh)",
    //height: "min(50vw, 50vh)",
    padding: "5px",
    border: "solid",
    boxShadow: "0 5px 5px 0px rgba(0,0,0,0.4)",
  };

  render() {
    return (
      <div className="container" style={this.backgroundStyle}>
        <div style={this.backgroundBoxStyle}>{this.props.children}</div>
      </div>
    );
  }
}
