/* Copyright (C) Andreas Goelzer - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Andreas Goelzer <agolzer@agolzer.com>, 2019
 */

import React, { Component } from "react";

import MvsdaFrame from "./common/mvsdaFrame";

export default class HomePage extends Component {
  render() {
    return (
      <MvsdaFrame>
        <h1>Captive Portal</h1>
      </MvsdaFrame>
    );
  }
}
