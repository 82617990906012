/* Copyright (C) Andreas Goelzer - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Andreas Goelzer <agolzer@agolzer.com>, 2019
 */

import React, { Component } from "react";
import axios from "axios";
//import BulletinBanner from "../resources/img/mvsda/bulletinBanner.gif";

import { QRCodeCanvas } from "qrcode.react";
import MvsdaFrame from "./common/mvsdaFrame";
import iphone_mac_1 from "../resources/img/iphone_mac_1.webp";
import iphone_mac_2 from "../resources/img/iphone_mac_2.webp";
import iphone_mac_3 from "../resources/img/iphone_mac_3.webp";

const PAGE_LOADING = "loading";
const PAGE_CONNECTING = "connecting";
const PAGE_INTRODUCTION = "introduction";
const PAGE_PRIVATE_MAC = "private_mac";
const PAGE_PRIVATE_MAC_IPHONE_INSTRUCTIONS = "private_mac_iphone_instructions";
const PAGE_PRIVATE_MAC_ANDROID_INSTRUCTIONS =
  "private_mac_android_instructions";
const PAGE_REGISTRATION = "registration";
const PAGE_QRCODE = "qrcode";

function getUrlArgs() {
  let args = { page: PAGE_LOADING };
  let url = new URL(window.location);
  url.searchParams.forEach((value, key) => {
    args[key] = value;
  });
  url.pathname.split("/").forEach((p) => {
    if (p !== "") {
      args.site = p;
    }
  });
  return args;
}

let styleButton = { width: "calc(100% - 10px)", height: "50px", margin: "5px" };

export default class GuestMvsdaPage extends Component {
  state = {
    registering: false,
    id: undefined,
    ap_ip: undefined,
    admins: [],
    errors: {},
    args: getUrlArgs(),
    name: "",
    email: "",
    cell: "",
    device: "",
    notes: "none",
  };

  async componentDidMount() {
    let res = await axios.post("https://captive.agolzer.com/api/sql.php", {
      action: "getAdmins",
      ssid: this.state.args.ssid,
    });
    if (!res.data.error) {
      let tmpAdmins = {};
      res.data.forEach((admin) => {
        if (tmpAdmins[admin.name] === undefined) {
          tmpAdmins[admin.name] = admin.device;
        } else {
          tmpAdmins[admin.name] += ", " + admin.device;
        }
      });
      let adminsSorted = [];
      Object.keys(tmpAdmins)
        .sort()
        .forEach((key) => {
          adminsSorted.push({ name: key, devices: tmpAdmins[key] });
        });
      this.setState({ admins: adminsSorted });
    }

    res = await axios.post("https://captive.agolzer.com/api/sql.php", {
      action: "hasaccess",
      mac: this.state.args.id,
      site: this.state.args.site,
      ssid: this.state.args.ssid,
    });
    if (!res.data.error) {
      let args = { ...this.state.args };
      if (res.data.hasAccess) {
        args.page = PAGE_CONNECTING;
      } else {
        args.page = PAGE_INTRODUCTION;
      }
      this.setState({ args });
    }
  }

  gotoPage = (page) => {
    let a = { ...this.state.args };
    a.page = page;
    this.setState({ args: a });
  };

  handleChange = ({ currentTarget: input }) => {
    this.setState({ [input.name]: input.value });
  };

  renderLoading() {
    return <MvsdaFrame></MvsdaFrame>;
  }

  renderConnecting() {
    setTimeout(() => {
      window.location.href = "http://www.mvsda.org/";
    }, 3000);

    return (
      <MvsdaFrame>
        <h2>Connecting to internet...</h2>
      </MvsdaFrame>
    );
  }

  renderIntroduction() {
    return (
      <MvsdaFrame>
        <h2>Welcome to MVSDA's WIFI Network!</h2>
        The use of this WIFI network is restricted. To use this network you need
        to register and agree to following terms:
        <ul>
          <li>Provide accurate information when registering</li>
          <li>
            All activity will be monitored and may be reported to law
            enforcement or church leadership
          </li>
          <li>Network bandwidth may be restricted or blocked at any time</li>
          <li>
            Once registered, church leadership needs to approve the request via
            the provided QR code.
          </li>
          <li>
            <b>Terms of Use:</b>
            <br /> By accessing the wireless network, you acknowledge that
            you're of legal age, you have read and understood and agree to be
            bound this agreement. You agree not to use the wireless network for
            any purpose that is unlawful and take full responsibility of your
            acts. The wireless network is provided "as is" without warranties of
            any kind, either expressed or implied.
          </li>
        </ul>
        <input
          type="button"
          value="Continue"
          style={styleButton}
          onClick={() => {
            let privateMacLetter =
              this.state.args.id &&
              this.state.args.id.length === 17 &&
              this.state.args.id.substring(1, 2).toLowerCase();
            if (privateMacLetter && "26ae".indexOf(privateMacLetter) !== -1) {
              this.gotoPage(PAGE_PRIVATE_MAC);
            } else {
              this.gotoPage(PAGE_REGISTRATION);
            }
          }}
        />
      </MvsdaFrame>
    );
  }

  renderPrivateMac() {
    return (
      <MvsdaFrame>
        <h2>&quot;Private Address&quot; needs to be disabled!</h2>
        To use this network, the &quot;Private Address&quot; needs to be
        disabled on your device. See instructions below.
        <input
          type="button"
          value="Instructions for iPhone/iPad"
          style={styleButton}
          onClick={() => {
            this.gotoPage(PAGE_PRIVATE_MAC_IPHONE_INSTRUCTIONS);
          }}
        />
        <input
          type="button"
          value="Instructions for Android"
          style={styleButton}
          onClick={() => {
            this.gotoPage(PAGE_PRIVATE_MAC_ANDROID_INSTRUCTIONS);
          }}
        />
      </MvsdaFrame>
    );
  }

  renderPrivateMacIphoneInstructions() {
    return (
      <MvsdaFrame>
        <h2>Disable Private Addresses on iPhone/iPad</h2> The
        <div>
          “Private Address” option is controlled separately for each Wi-Fi
          network. We don’t recommend disabling it for a network unless you need
          to do so (or are troubleshooting a problem.) To find this option, head
          to Settings &gt; Wi-Fi on your iPhone or iPad. Locate the Wi-Fi
          network you want to change this option for — it will be at the top of
          the screen if you’re currently connected to it. Tap the “i” icon at
          the right side of the Wi-Fi network name.
        </div>
        <img src={iphone_mac_1} style={{ width: "25vw" }} alt="" />
        <div>
          Disable the “Private Address” option by tapping the switch to its
          right.
        </div>
        <div>
          The “Wi-Fi Address” option here shows you the private MAC address
          being used on that specific network, just in case you need it—to
          configure something on a router, for example.
        </div>
        <img src={iphone_mac_2} style={{ width: "25vw" }} alt="" />
        <div>
          You’ll be prompted to rejoin the network if you’re already connected.
          Tap “Rejoin” and your iPhone or iPad will rejoin with its normal MAC
          address.
        </div>
        <img src={iphone_mac_3} style={{ width: "25vw" }} alt="" />
      </MvsdaFrame>
    );
  }

  renderPrivateMacAndroidInstructions() {
    return (
      <MvsdaFrame>
        <h2>
          How to Disable Private Addresses for a Wi-Fi Network on Android
          devices
        </h2>{" "}
        <h3>Android 10 for most devices</h3>
        <ul>
          <li>Step 1: Go to the Settings app on your Android device.</li>
          <li>Step 2: Tap on Network & Internet or Connections &gt; Wi-Fi.</li>
          <li>Step 3: Tap the gear icon next to the MVSDA Wi-Fi name.</li>
          <li>
            Step 4: Tap on <b>MAC address type</b>.
          </li>
          <li>
            Step 5: Select <b>Use phone MAC</b>.
          </li>
          <li>Step 6: Turn OFF your device's Wi-Fi and then ON again.</li>
        </ul>
        <h3>Samsung Galaxy® devices</h3>
        <ul>
          <li>Step 1: Go to the Settings app on your Android device.</li>
          <li>
            Step 2: Tap on <b>Connections &gt; Wi-Fi</b>.
          </li>
          <li>Step 3: Tap the gear icon next to the MVSDA Wi-Fi name.</li>
          <li>
            Step 4: Tap on <b>MAC address type</b>.
          </li>
          <li>
            Step 5: Select <b>Use phone MAC</b>.
          </li>
          <li>Step 6: Turn OFF your device's Wi-Fi and then ON again.</li>
        </ul>
      </MvsdaFrame>
    );
  }

  renderField(key, label, readOnly = false) {
    return (
      <div className="form-group">
        <label htmlFor={key}>{label}</label>
        <input
          type="text"
          name={key}
          id={key}
          value={this.state[key]}
          onChange={this.handleChange}
          disabled={readOnly}
        />
        {this.state.errors[key] && (
          <div style={{ background: "#FF8888" }}>{this.state.errors[key]}</div>
        )}
      </div>
    );
  }

  renderRegistration() {
    return (
      <MvsdaFrame>
        <h2>Register for the MVSDA Network</h2>

        {this.renderField("name", "Name")}
        {this.renderField("email", "Email")}
        {this.renderField("cell", "Cell Phone")}
        {this.renderField("device", "Device (i.e. iPad, Galaxy, ...)")}
        <div style={{ color: "#888888", fontSize: "8px" }}>
          MAC Address: {this.state.args.id}
        </div>
        <div>&nbsp;</div>
        <input
          type="button"
          value={this.state.registering ? "Registering....." : "Continue"}
          disabled={this.state.registering}
          style={styleButton}
          onClick={async () => {
            let errors = {};
            if (!this.state.name) errors.name = '"Name" field is required';
            if (!this.state.email) errors.email = '"Email" field is required';
            if (!this.state.cell)
              errors.cell = '"Cell Phone" field is required';
            if (!this.state.device)
              errors.device = '"Device" field is required';
            this.setState({ errors });
            if (Object.keys(errors).length > 0) {
              return;
            }

            this.setState({ registering: true });

            let res = await axios.post(
              "https://captive.agolzer.com/api/sql.php",
              {
                action: "register",
                mac: this.state.args.id,
                site: this.state.args.site,
                ssid: this.state.args.ssid,
                ap: this.state.args.ap,
                name: this.state.name,
                email: this.state.email,
                cell: this.state.cell,
                device: this.state.device,
                notes: this.state.notes,
              }
            );
            if (res.data.id && res.data.ap_ip) {
              this.setState({ id: res.data.id, ap_ip: res.data.ap_ip });
              this.gotoPage(PAGE_QRCODE);
            } else {
              alert("Configuration failed. " + JSON.stringify(res.data));
            }
          }}
        />
      </MvsdaFrame>
    );
  }

  renderQrcode() {
    const url = "http://" + this.state.ap_ip + "/id/" + this.state.id;
    return (
      <div className="row" style={this.backgroundStyle}>
        <div className="col" style={this.backgroundBoxStyle}>
          <h2>Registration complete.</h2> Please get WIFI access approved by one
          of the Church Leaders listed below. The Church leader needs to be
          connected to the Church WIFI.
          <QRCodeCanvas
            id="qrCode"
            value={url}
            size={300}
            bgColor={"#00ff00"}
            level={"H"}
          />
          <hr />
          <table border="1">
            <tr>
              <th>Name</th>
              <th>Devices</th>
            </tr>
            {this.state.admins.map((admin) => (
              <tr>
                <td>{admin.name}</td>
                <td>{admin.devices}</td>
              </tr>
            ))}
          </table>
          <br />
          {url}
          <br />
          Once Internet access has been approved, click{" "}
          <a href={this.state.args.url}>here.</a>
          {this.state.args.url}
        </div>
      </div>
    );
  }

  render() {
    return (
      <React.Fragment>
        {this.state.args.page === PAGE_LOADING
          ? this.renderLoading()
          : this.state.args.page === PAGE_CONNECTING
          ? this.renderConnecting()
          : this.state.args.page === PAGE_INTRODUCTION
          ? this.renderIntroduction()
          : this.state.args.page === PAGE_PRIVATE_MAC
          ? this.renderPrivateMac()
          : this.state.args.page === PAGE_PRIVATE_MAC_IPHONE_INSTRUCTIONS
          ? this.renderPrivateMacIphoneInstructions()
          : this.state.args.page === PAGE_PRIVATE_MAC_ANDROID_INSTRUCTIONS
          ? this.renderPrivateMacAndroidInstructions()
          : this.state.args.page === PAGE_REGISTRATION
          ? this.renderRegistration()
          : this.state.args.page === PAGE_QRCODE
          ? this.renderQrcode()
          : "Invalid Page"}
      </React.Fragment>
    );
  }
}
