/* Copyright (C) Andreas Goelzer - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Andreas Goelzer <agolzer@agolzer.com>, 2019
 */

import React, { Component } from "react";
import MvsdaFrame from "./common/mvsdaFrame";

export default class GuestPage extends Component {
  state = {
    ssid: undefined,
    url: undefined,
    mac: undefined,
  };

  componentDidMount() {
    let ssid = undefined;
    let url = undefined;
    let mac = undefined;
    const urll = new URL(window.location);
    urll.searchParams.forEach((value, key) => {
      if (key === "ssid" && value) {
        ssid = value;
      } else if (key === "url" && value) {
        url = value;
      } else if (key === "id" && value) {
        mac = value;
      }
    });
    this.setState({ ssid, url, mac });
  }

  render() {
    return (
      <MvsdaFrame>
        <div>SSID: {this.state.ssid}</div>
        <div>URL: {this.state.url}</div>
        <div>MAC: {this.state.mac}</div>
      </MvsdaFrame>
    );
  }
}
