/* Copyright (C) Andreas Goelzer - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Andreas Goelzer <agolzer@agolzer.com>, 2019
 */

import React, { Component } from "react";
import GuestMvsdaPage from "./guestMvsdaPage";
import ValidatePage from "./validatePage";

export default class GuestPage extends Component {
  state = {
    ssid: undefined,
    url: undefined,
  };

  componentDidMount() {
    let ssid = undefined;
    let url = "";
    const urll = new URL(window.location);
    urll.searchParams.forEach((value, key) => {
      if (key === "ssid" && value) {
        ssid = value;
      } else if (key === "url" && value) {
        url = value;
      }
    });
    this.setState({ ssid, url });
  }

  render() {
    if (
      this.state.url &&
      this.state.url.startsWith("http://192.168.") &&
      this.state.url.indexOf("/id/") !== -1
    ) {
      return <ValidatePage />;
    } else if (this.state.ssid === "MVSDA-Guest") {
      return <GuestMvsdaPage />;
    } else {
      return <h1>Invalid SSID: {this.state.ssid}</h1>;
    }
  }
}
