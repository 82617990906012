/* Copyright (C) Andreas Goelzer - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Andreas Goelzer <agolzer@agolzer.com>, 2019
 */

import React, { Component } from "react";
import axios from "axios";
import MvsdaFrame from "./common/mvsdaFrame";
export default class ValidatePage extends Component {
  state = {
    debug: false,
    page: 0,
    errors: {},
    page1Message: "",
    admins: undefined,
    allAdmins: [],
  };

  async componentDidMount() {
    let url = undefined;
    let approver = undefined;
    new URL(window.location).searchParams.forEach((value, key) => {
      if (key === "url") url = value;
      else if (key === "id") approver = value;
    });
    let posId = url.indexOf("/id/");
    if (posId !== -1) {
      const id = url.substring(posId + "/id/".length);
      let res = await axios.post("https://captive.agolzer.com/api/sql.php", {
        action: "get",
        id: id,
      });
      let resAdmins = await axios.post(
        "https://captive.agolzer.com/api/sql.php",
        {
          action: "getAdmins",
          ssid: res.data.ssid,
          mac: approver,
        }
      );
      if (res.data.error) {
        res.data = [];
      }

      let resAllAdmins = await axios.post(
        "https://captive.agolzer.com/api/sql.php",
        {
          action: "getAdmins",
          ssid: res.data.ssid,
        }
      );
      if (!resAllAdmins.data.error) {
        this.setState({ allAdmins: resAllAdmins.data });
      }

      this.setState({ ...res.data, approver, admins: resAdmins.data });
    }
  }

  renderField(key, label, readOnly = false) {
    return (
      <div className="form-group">
        <label htmlFor={key}>{label}</label>
        <span className="d-none d-print-block"></span>
        <div
          style={{ display: "flex", flexDirection: "column", flex: "1 1 auto" }}
        >
          <input
            name={key}
            id={key}
            value={this.state[key]}
            onChange={({ currentTarget: input }) =>
              this.setState({ [input.name]: input.value })
            }
            disabled={readOnly}
          />
          {this.state.errors[key] && (
            <div style={{ background: "#FF8888" }}>
              {this.state.errors[key]}
            </div>
          )}
        </div>
      </div>
    );
  }

  renderMinutesField() {
    return (
      <div className="form-group">
        <label htmlFor="minutes">Minutes</label>
        <span className="d-none d-print-block"></span>
        <div
          style={{ display: "flex", flexDirection: "column", flex: "1 1 auto" }}
        >
          <select
            name="minutes"
            id="minutes"
            value={String(this.state.minutes)}
            onChange={({ currentTarget: input }) =>
              this.setState({ minutes: parseInt(input.value) })
            }
          >
            <option value="1440">1 day</option>
            <option value="4320">3 days</option>
            <option value="43200">1 month</option>
            <option value="10512000">Unlimited</option>
          </select>
          {this.state.errors.minutes && (
            <div style={{ background: "#FF8888" }}>
              {this.state.errors.minutes}
            </div>
          )}
        </div>
      </div>
    );
  }

  renderForm() {
    return (
      <MvsdaFrame>
        <h2>WIFI access requested</h2>
        If appropriate, approve access to the MVSDA Church WIFI. Please make
        sure the registration data is accurate.
        <br />
        <br />
        {this.state.debug && (
          <React.Fragment>
            {this.renderField("site", "Site", true)}
            {this.renderField("ssid", "SSID", true)}
            {this.renderField("ap", "Access Point MAC", true)}
            {this.renderField("mac", "Client MAC", true)}
          </React.Fragment>
        )}
        {this.renderField("name", "Name")}
        {this.renderField("email", "Email")}
        {this.renderField("cell", "Cell Phone")}
        {this.renderField("device", "Device")}
        {this.renderField("notes", "Notes")}
        <b>Adjust the duration for the WIFI access appropriately</b>
        {this.renderMinutesField()}
        <input
          type="button"
          value="Confirm"
          onClick={async () => {
            let res = await axios.post(
              "https://captive.agolzer.com/api/sql.php",
              {
                action: "approve",
                approver: this.state.approver,
                id: this.state.id,
                name: this.state.name,
                email: this.state.email,
                cell: this.state.cell,
                device: this.state.device,
                notes: this.state.notes,
                minutes: this.state.minutes,
              }
            );
            if (res.data.error) {
              this.setState({
                page: 1,
                page1Message:
                  this.state.name +
                  " was NOT registerd. Error: " +
                  res.data.error,
              });
            } else {
              this.setState({
                page: 1,
                page1Message: this.state.name + " was registered successfully.",
              });
            }
          }}
        />
        <div
          style={{
            fontSize: "8px",
            textAlign: "right",
            width: "100%",
            cursor: "pointer",
          }}
          onClick={() => this.setState({ debug: !this.state.debug })}
        >
          Debug
        </div>
      </MvsdaFrame>
    );
  }

  renderNoAdmin() {
    return (
      <MvsdaFrame>
        <h1>You cannot approve since you are not an admin</h1>
        Your MAC address: {this.state.approver}
        <br />
        <br />
        These are the admins:
        <br />
        <table border="1">
          <thead>
            <tr>
              <th>Name</th>
              <th>Device</th>
            </tr>
          </thead>
          <tbody>
            {this.state.allAdmins.map((admin, index) => (
              <tr key={index}>
                <td>{admin.name}</td>
                <td>{admin.device}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </MvsdaFrame>
    );
  }

  renderPage1Message() {
    return (
      <MvsdaFrame>
        <h1>{this.state.page1Message}</h1>
      </MvsdaFrame>
    );
  }

  render() {
    if (this.state.admins !== undefined && this.state.admins.length === 0) {
      return this.renderNoAdmin();
    }
    if (this.state.page === 1) {
      return this.renderPage1Message();
    }
    return this.renderForm();
  }
}
