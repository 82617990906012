import React, { Component } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./App.css";
import HomePage from "./components/homePage";
import GuestPage from "./components/guestPage";
import ValidatePage from "./components/validatePage";
import InfoPage from "./components/infoPage";

export default class App extends Component {
  render() {
    return (
      <React.Fragment>
        <main className="container">
          <BrowserRouter>
            <Routes>
              <Route
                path={`${process.env.PUBLIC_URL}/guest/s/:id`}
                element={<GuestPage />}
              />
              <Route
                path={`${process.env.PUBLIC_URL}/validate`}
                element={<ValidatePage />}
              />
              <Route
                path={`${process.env.PUBLIC_URL}/info`}
                element={<InfoPage />}
              />
              <Route
                path={`${process.env.PUBLIC_URL}/`}
                element={<HomePage />}
              />
            </Routes>
          </BrowserRouter>
        </main>
      </React.Fragment>
    );
  }
}
